import { useFormik } from "formik";
import React, { useState } from "react";
import { useLocation } from "react-router-dom";
import * as Yup from "yup";

const CWCEventForm = () => {
  const location = useLocation();
  const pathName = location.pathname?.split("/")[2] || "";

  const [loading, setLoading] = useState(false);
  const [initialValues, setInitialValues] = useState({
    FirstName: "",
    EmailID: "",
    MobileNo: "",
  });

  const phoneRegExp = /^[0-9]{6,10}$/;

  const validationSchema = Yup.object().shape({
    FirstName: Yup.string().required("First Name is required"),
    MobileNo: Yup.string()
      .required("Mobile number is required")
      .matches(phoneRegExp, "Mobile number is not valid"),
    EmailID: Yup.string()
      .email("Invalid email")
      .required("Email Address is required"),
  });

  const onSubmit = async (values) => {
    console.log("values", values);
    // if (EventGUID) {
    //   const editPayload = {
    //     EventID: commonEventID ? +commonEventID : null,
    //     EventGUID: EventGUID,
    //     FirstName: values?.FirstName ? values?.FirstName : "",
    //     LastName: values?.LastName ? values?.LastName : "",
    //     EmailID: values?.EmailID ? values?.EmailID : "",
    //     MobileNo: values?.MobileNo ? values?.MobileNo?.toString() : "",
    //     BranchID: values?.BranchID ? +values?.BranchID : null,
    //     ExamGivenID: values?.ExamGivenID ? +values?.ExamGivenID : null,
    //     CountryPreference: "1",
    //     CollegePreferences: values?.CollegePreferences
    //       ? values?.CollegePreferences
    //       : "",
    //   };
    //   try {
    //     setLoading(true);
    //     const response = await fetch(
    //       `${PORTAL_BACKEND_BASE_URL}/EventMainForm/UpdateEventMainForm`,
    //       {
    //         method: "POST",
    //         headers: {
    //           "Content-Type": "application/json",
    //         },
    //         body: JSON.stringify(editPayload),
    //       }
    //     );
    //     const responseData = await response.json();
    //     console.log("responseData", responseData);
    //     if (responseData.Status === 200) {
    //       SuccessToast(responseData?.Details);
    //       // SuccessToast(imageResponseData?.Details);
    //       navigate("/events/thankyou/");
    //     } else {
    //       ErrorToast(responseData?.Details);
    //     }
    //   } catch (error) {
    //     ErrorToast(error?.message || "Network Error");
    //     console.error("An error occurred during the API call:", error);
    //   } finally {
    //     setLoading(false);
    //   }
    // } else {
    //   const insertPayload = {
    //     EventID: commonEventID ? +commonEventID : null,
    //     FirstName: values?.FirstName ? values?.FirstName : "",
    //     LastName: values?.LastName ? values?.LastName : "",
    //     EmailID: values?.EmailID ? values?.EmailID : "",
    //     MobileNo: values?.MobileNo ? values?.MobileNo?.toString() : "",
    //     BranchID: values?.BranchID ? +values?.BranchID : "",
    //     CountryPreference: "1",
    //     ExamGivenID: values?.ExamGivenID ? +values?.ExamGivenID : null,
    //     CollegePreferences: values?.CollegePreferences
    //       ? values?.CollegePreferences
    //       : "",
    //     Campaign: Campaign
    //       ? Campaign === "walkin"
    //         ? values?.Campaign
    //         : Campaign
    //       : "website",
    //   };
    //   console.log("insertPayload", insertPayload);
    //   try {
    //     setLoading(true);
    //     const response = await fetch(
    //       `${PORTAL_BACKEND_BASE_URL}/EventMainForm/AddEventMainForm`,
    //       {
    //         method: "POST",
    //         headers: {
    //           "Content-Type": "application/json",
    //         },
    //         body: JSON.stringify(insertPayload),
    //       }
    //     );
    //     const responseData = await response.json();
    //     console.log("responseData", responseData);
    //     if (responseData.Status === 200) {
    //       // setLoading(false);
    //       navigate("/events/thankyou/");
    //       SuccessToast(responseData?.Details);
    //       // setShowSuccessToast(false);
    //       // SuccessToast(imageResponseData?.Details);
    //     } else {
    //       ErrorToast(responseData?.Details);
    //     }
    //   } catch (error) {
    //     ErrorToast(error?.message || "Network Error");
    //     console.error("An error occurred during the API call:", error);
    //   } finally {
    //     setLoading(false);
    //   }
    // }
  };

  const formik = useFormik({
    initialValues,
    onSubmit,
    validationSchema,
    enableReinitialize: true,
  });

  return (
    <form onSubmit={formik.handleSubmit} className="cwc-event-form">
      <div className="row">
        <div className="col-12 d-flex">
          <div
            className="card flex-fill"
            style={{
              borderRadius: 30,
              background:
                pathName === "coffeewithcareerline2" ? "#275AA6" : "#fff",
              color: pathName === "coffeewithcareerline2" ? "#fff" : "#000",
            }}
          >
            <div className="card-body">
              <h4
                className="card-title"
                style={{
                  color:
                    pathName === "coffeewithcareerline2" ? "#fff" : "#275AA6",
                }}
              >
                Claim Your Free Seat Now
              </h4>
              <div>
                <div className="form-group row">
                  <label className="col-12 col-form-label fw-bold">
                    Your Name<code>*</code>
                  </label>
                  <div className="col-12">
                    <input
                      name="FirstName"
                      type="text"
                      id="FirstName"
                      placeholder="Your Name"
                      className={`form-control ${
                        formik.errors.FirstName &&
                        formik.touched.FirstName &&
                        "error-border"
                      }`}
                      maxLength="50"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.FirstName}
                    />
                  </div>
                </div>
                <div className="form-group row">
                  <label className="col-12 col-form-label fw-bold">
                    Email Address<code>*</code>
                  </label>
                  <div className="col-12">
                    <input
                      name="EmailID"
                      type="email"
                      id="EmailID"
                      placeholder="i.e. example@gmail.com"
                      className={`form-control ${
                        formik.errors.EmailID &&
                        formik.touched.EmailID &&
                        "error-border"
                      }`}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.EmailID}
                    />
                  </div>
                </div>
                <div className="form-group row">
                  <label className="col-12 col-form-label fw-bold">
                    Mobile No<code>*</code>
                  </label>
                  <div className="col-12">
                    <input
                      name="MobileNo"
                      type="number"
                      id="MobileNo"
                      placeholder="i.e. 9999999999"
                      className={`form-control ${
                        formik.errors.MobileNo &&
                        formik.touched.MobileNo &&
                        "error-border"
                      }`}
                      max={9999999999}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.MobileNo}
                    />
                  </div>
                </div>
              </div>
              <div>
                <button
                  id="submit-btn"
                  className="submit-btn"
                  type="submit"
                  disabled={loading}
                >
                  {loading ? "Submiting" : "Reserve My FREE Spot!"}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </form>
  );
};

export default CWCEventForm;
